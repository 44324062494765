.listing-card,
.listing-card * {
  user-select: none;
}

.listing-card {
  display: flex;
  gap: 15px;
  border-radius: 10px;
}

.listing-card[data-ready="false"] {
  background-color: var(--background-color-secondary);
}

.listing-card-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: var(--background-color-secondary);
  overflow: hidden;
  flex-shrink: 0;
}

.listing-card-image-inner {
  width: inherit;
  height: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.listing-card .flag-ribbon {
  top: 0px !important;
  right: 0px !important;
  width: 45px !important;
  height: 45px !important;
}

.listing-card .flag-icon {
  left: 6px !important;
  bottom: 6px !important;
  width: 22px !important;
  height: 22px !important;
}

.listing-card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: calc(100% - 190px);
}

.listing-card-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 38px;
  padding-top: 5px;
  color: var(--text-color-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.listing-card-details {
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color-tertiary);
}

.listing-card-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-card-button {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: #FFF;
  height: 32px;
  line-height: 30px;
  width: 80px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 10px;
  cursor: pointer;
}

@media (min-width: 620px) {
  .listing-card-image {
    width: 100px;
    height: 100px;
  }

  .listing-card-title {
    font-size: 18px;
    line-height: 42px;
    padding-top: 15px;
  }

  .listing-card-details {
    font-size: 13px;
  }
}
