.avatar {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  overflow: hidden;
  background-color: var(--background-color-secondary);
}

.avatar.clickable {
  cursor: pointer;
}

.avatar[data-ready="false"] {
  background-size: 18px auto;
}

.avatar-image {
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
