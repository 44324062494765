.profile-popup,
.profile-popup * {
  user-select: none;
}

.profile-popup {
  width: 100%;
  margin: 10px !important;
  background-color: var(--background-color-primary) !important;
  max-width: 450px !important;
  border-radius: 20px !important;
}

.profile-popup-header {
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
}

.profile-popup-close-button {
  float: right;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  color: var(--text-color-primary);
}

.profile-popup-title {
  line-height: 20px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
  color: var(--text-color-primary);
}

.profile-popup-image {
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  margin: 20px auto;
  border: 10px solid #222;
  box-shadow: 0px 0px 10px #1112;
  border-radius: 200px;
}

.profile-popup-name {
  color: var(--color-primary);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.profile-popup-logout {
  background-color: var(--color-primary);
  width: 150px;
  height: 45px;
  color: var(--background-color-primary);
  border: none;
  margin: 40px auto 30px auto;
  border-radius: 15px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
