* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  font-family: "VisbyRoundCF";
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

html {
  touch-action: pan-y;
}

body {
  overscroll-behavior: contain;
  background-color: var(--background-color-primary);
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: #FFF;
}

body[data-theme="dark"] *::-webkit-scrollbar-track,
body[data-theme="dark"]::-webkit-scrollbar-track {
  background-color: #111;
}

@media (min-width: 780px) {
  body::-webkit-scrollbar-track {
    background-color: #222 !important;
  }
}

*::-webkit-scrollbar-thumb {
  background-color: #3c90f5;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body[data-animations="true"] {
  transition-duration: 0.2s;
  transition-property: background, color;
}

body[data-overflow="false"] {
  overflow-y: hidden;
}

@media (min-width: 620px) {
  body[data-overflow="false"] {
    margin-right: 5px;
  }
}

[data-ready="false"] {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23888'%3E%3Cg%3E%3Crect x='11' y='1' width='2' height='5' opacity='.14' /%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(30 12 12)' opacity='.29' /%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(60 12 12)' opacity='.43' /%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(90 12 12)' opacity='.57' /%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(120 12 12)' opacity='.71' /%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(150 12 12)' opacity='.86' /%3E%3Crect x='11' y='1' width='2' height='5' transform='rotate(180 12 12)' /%3E%3CanimateTransform attributeName='transform' type='rotate' calcMode='discrete' dur='0.75s' values='0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12' repeatCount='indefinite' /%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
}

[data-animations="true"] [data-ready] * {
  transition: opacity 0.3s;
}

[data-ready="false"] * {
  opacity: 0 !important;
}

[data-ready="true"] * {
  opacity: 1;
}

@media (min-width: 780px) {
  #root {
    max-width: 900px;
    margin: 0px auto;
    box-shadow: 20px 20px 2px 50vw #222;
    min-height: 100vh;
  }
}