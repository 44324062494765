.settings-page,
.settings-page * {
  user-select: none;
}

.settings-page-title {
  font-weight: 700;
  font-size: 25px;
  padding: 10px 20px 0px 20px;
  color: var(--text-color-primary);
}

.settings-page-content {
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}


.settings-page-item {
  display: flex;
  gap: 30px;
  color: var(--text-color-secondary);
  align-items: center;
}

.settings-page-item-label {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.settings-page-item-label-title {
  line-height: 24px;
  font-weight: 700;
}

.settings-page-item-label-description {
  font-size: 11px;
  font-weight: 600;
  color: var(--text-color-tertiary);
}

.settings-page-item-switch {
  background-color: var(--text-color-tertiary);
  border: none;
  border-radius: 40px;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg height='100' width='100' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle r='45' cx='50' cy='50' fill='%23FFF' /%3E%3C/svg%3E");
  background-position: 5px 5px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  transition: background 0.15s;
  cursor: pointer;
  width: 50px;
  height: 30px;
  padding: 0px;
  margin: 0px;
  flex-shrink: 0;
}

.settings-page-item-switch[data-enabled="true"] {
  background-color: var(--color-primary);
  background-position: 25px 5px;
}
