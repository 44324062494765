.login {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

.login-logo {
  width: 180px;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

[data-theme="light"] .login-logo {
  background-image: url(../assets/logo-light.png);
}

[data-theme="dark"] .login-logo {
  background-image: url(../assets/logo-dark.png);
}

.login-spinner {
  width: 25px !important;
  height: 25px !important;
  color: var(--text-color-tertiary) !important;
}

.login-text {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-tertiary);
}
