.apps-page,
.apps-page * {
  user-select: none;
}

.apps-page.loading {
  color: var(--color-primary) !important;
  display: flex;
  justify-content: center;
  padding-top: calc(50vh - 120px - 70px);
  opacity: 0.6;
}

.apps-page-title {
  font-weight: 700;
  font-size: 22px;
  padding: 10px 20px 0px 20px;
  color: var(--text-color-primary);
}

.apps-page-title.large {
  font-size: 25px;
}

.apps-page-content.featured {
  gap: 25px;
  padding: 20px 20px;
}

.apps-page-content.slider {
  gap: 20px;
  padding: 20px 20px;
}

.apps-page-content.listing {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px;
}

.apps-page-footer {
  padding: 10px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apps-page-footer-button {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: #FFF;
  height: 38px;
  line-height: 30px;
  width: 120px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 10px;
  cursor: pointer;
}

[data-section-count="1"] .section-slider {
  content: "no-slider";
}

[data-section-count="1"] .featured-card {
  width: 100% !important;
  max-width: none;
}

[data-section-count="1"] .slider-card {
  width: calc(100% - 30px) !important;
  max-width: none;
}

.apps-page.empty {
  padding: calc(50vh - 20px - 10px - 160px) 30px 0px 30px;
  display: flex;
  justify-content: center;
  color: var(--text-color-tertiary);
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}
