.search {
  width: calc(100% - 40px);
  background-color: var(--background-color-primary);
  padding: 20px;
  z-index: 1;
  box-shadow: 0px 1px 6px #1110;
  transition: box-shadow 0.2s;
  position: relative;
}

[data-stick="true"] .search {
  position: fixed;
  left: 0px;
  top: 0px;
  box-shadow: 0px 1px 6px #1112;
}

@media (min-width: 900px) {
  .search {
    width: calc(900px - 40px);
  }

  [data-stick="true"] .search {
    left: calc(50vw - 452.5px);
  }
}

.search-input {
  padding: 0px 30px 0px 60px;
  width: calc(100% - 90px);
  border: none;
  background-color: var(--background-color-secondary);
  height: 60px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 600;
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(153, 160, 160); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path></g></svg>');
  background-position: 15px 15px;
  background-size: 30px auto;
  background-repeat: no-repeat;
  color: var(--text-color-secondary);
  user-select: text;
}

.search-input::placeholder {
  font-weight: 500;
}

.search-close-button {
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 2;
  top: 35px;
  right: 35px;
  border-radius: 40px;
  border: none;
  background-color: var(--color-primary);
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></g></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  cursor: pointer;
}

.search-result {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px - 100px - 100px);
  position: fixed;
  top: 100px;
  left: 0px;
  z-index: 0;
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 120px 20px;
  overflow-y: auto;
}

@media (min-width: 900px) {
  .search-result {
    width: calc(900px - 40px);
    left: calc(50vw - 452.5px);
  }
}

.search-result-empty {
  padding: 30px;
  display: flex;
  justify-content: center;
  color: var(--text-color-tertiary);
  font-weight: 700;
  font-size: 15px;
}
