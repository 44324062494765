.featured-card,
.featured-card * {
  user-select: none;
}

.featured-card {
  width: calc(100vw - 70px);
  height: 200px;
  background-color: var(--background-color-secondary);
  border-radius: 18px;
  overflow: hidden;
}

@media (min-width: 620px) {
  .featured-card {
    height: 280px;
  }

  .featured-card-inner {
    background-position: center !important;
    background-size: 160px auto, cover !important;
  }
}

@media (min-width: 780px) {
  .featured-card {
    width: calc(50vw + 120px);
    max-width: 700px;
    height: 380px;
  }

  .featured-card-inner {
    background-position: center !important;
    background-size: 180px auto, cover !important;
  }
}

.featured-card-inner {
  width: 100%;
  height: 100%;
  background-position: center 40%, center;
  background-repeat: no-repeat;
  background-size: 120px auto, cover;
  position: relative;
}

.featured-card .flag-ribbon {
  top: 0px !important;
  right: 0px !important;
  width: 70px !important;
  height: 70px !important;
}

.featured-card .flag-icon {
  top: 10px !important;
  left: 10px !important;
  width: 30px !important;
  height: 30px !important;
}

.featured-card-content {
  background: linear-gradient(#0000, #000A);
  position: absolute;
  bottom: 0;
  color: #FFF;
  padding: 120px 18px 18px 18px;
  display: flex;
  width: calc(100% - 36px);
  align-items: end;
}

.featured-card-content-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.featured-card-title {
  font-weight: 600;
  font-size: 18px;
}

.featured-card-details {
  font-size: 11px;
  font-weight: 500;
  color: #FFFA;
}

.featured-card-content-buttons {
  display: flex;
}

.featured-card-button {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: #FFF;
  height: 32px;
  line-height: 30px;
  width: 80px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 10px;
  cursor: pointer;
}
