[data-theme="light"] {
  --color-primary: #3C90F5;
  --color-secondary: #ECB214;
  --background-color-primary: #FFFFFF;
  --background-color-secondary: #f6f6f6;
  --background-color-tertiary: #ebebeb;
  --text-color-primary: #000000;
  --text-color-secondary: #3F3F3F;
  --text-color-tertiary: #BBBBBB;
}

[data-theme="dark"] {
  --color-primary: #3C90F5;
  --color-secondary: #ECB214;
  --background-color-primary: #111111;
  --background-color-secondary: #212121;
  --background-color-tertiary: #303030;
  --text-color-primary: #c0c0c0;
  --text-color-secondary: #A6A6A6;
  --text-color-tertiary: #707070;
}
