.home.apps,
.home.favorites {
  padding-bottom: 120px;
}

[data-stick="true"] .header {
  margin-bottom: 100px;
}

.home.leaderboard .header,
.home.settings .header {
  margin-bottom: 0px !important;
}
