.details,
.details * {
  user-select: none;
}

.details {
  background-color: var(--background-color-primary) !important;
  width: 100% !important;
  margin: 0px !important;
  height: calc(100vh - calc(100vh - 100%)) !important;
  max-height: none !important;
  max-width: 620px !important;
  position: relative;
  border-radius: 0px !important;
}

.details-inner {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.details-cover {
  height: 250px;
  background-color: var(--background-color-secondary);
}

@media (max-height: 790px) {
  .details-cover {
    height: 180px;
  }

  .details-cover-inner {
    background-size: 120px auto, cover !important;
    background-position: center 40%, center !important;
  }

  .details-cover-icon {
    height: 40px !important;
    width: 40px !important;
  }

  .details-infos {
    padding: 10px !important;
    gap: 0px !important;
  }

  .details-header {
    padding: 20px 20px 5px 20px !important;
  }
}

.details-cover-inner {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150px auto, cover;
  position: relative;
}

.details-cover-icons {
  position: absolute;
  bottom: 15px;
  height: 45px;
  display: flex;
  gap: 10px;
  left: 15px;
  align-items: end;
}

.details-cover-icon {
  width: 45px;
  height: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.details-cover-icon.paid {
  background-image: url(../../assets/icons/paid.png);
}

.details-cover-icon.global {
  background-image: url(../../assets/icons/global.png);
}

.details-cover-icon.rewards {
  background-image: url(../../assets/icons/rewards.png);
}

.details-close-button {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
  z-index: 1;
}

.details-close-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.details-header {
  height: 50px;
  line-height: 50px;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.details-title {
  font-weight: 700;
  font-size: 22px;
  flex-grow: 1;
  color: var(--text-color-primary);
}

.details-favorite-button {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2zm-3.566 15.604c.881-.556 1.676-1.109 2.42-1.701C18.335 14.533 20 11.943 20 9c0-2.36-1.537-4-3.5-4-1.076 0-2.24.57-3.086 1.414L12 7.828l-1.414-1.414C9.74 5.57 8.576 5 7.5 5 5.56 5 4 6.656 4 9c0 2.944 1.666 5.533 4.645 7.903.745.592 1.54 1.145 2.421 1.7.299.189.595.37.934.572.339-.202.635-.383.934-.571z"></path></g></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px auto;
  cursor: pointer;
}

.details-favorite-button[data-active="true"] {
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z"></path></g></svg>');
}

[data-theme="dark"] .details-favorite-button {
  filter: invert(100%);
  opacity: 0.8;
}

.details-tag-list {
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.details-tag-item {
  height: 18px;
  line-height: 18px;
  border-radius: 50px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid var(--color-primary);
  color: var(--text-color-secondary);
}

.details-info-list {
  margin: 20px 20px 60px 20px;
  background-color: var(--background-color-secondary);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.details-info-item {
  display: flex;
  gap: 5px;
  align-items: center;
}

.details-info-item-icon {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px auto;
}

.details-info-item-icon.price {
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(60, 144, 245); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm2.828 8.486a2 2 0 1 0 2.828-2.829 2 2 0 0 0-2.828 2.829z"></path></g></svg>');
}

.details-info-item-icon.winners {
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(60, 144, 245); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M13 16.938V19h5v2H6v-2h5v-2.062A8.001 8.001 0 0 1 4 9V3h16v6a8.001 8.001 0 0 1-7 7.938zM1 5h2v4H1V5zm20 0h2v4h-2V5z"></path></g></svg>');
}

.details-info-item-icon.global {
  background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(60, 144, 245); opacity: 1; transform: rotate(0deg) scale(1, 1);"><g><path d="M2.05 13h5.477a17.9 17.9 0 0 0 2.925 8.88A10.005 10.005 0 0 1 2.05 13zm0-2a10.005 10.005 0 0 1 8.402-8.88A17.9 17.9 0 0 0 7.527 11H2.05zm19.9 0h-5.477a17.9 17.9 0 0 0-2.925-8.88A10.005 10.005 0 0 1 21.95 11zm0 2a10.005 10.005 0 0 1-8.402 8.88A17.9 17.9 0 0 0 16.473 13h5.478zM9.53 13h4.94A15.908 15.908 0 0 1 12 20.592 15.908 15.908 0 0 1 9.53 13zm0-2A15.908 15.908 0 0 1 12 3.408 15.908 15.908 0 0 1 14.47 11H9.53z"></path></g></svg>');
}

.details-info-item-text {
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color-secondary);
}

.details-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  border: none;
  padding: 0px;
  height: 60px;
  background-color: var(--color-primary);
  border-radius: 80px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--background-color-primary);
  cursor: pointer;
  overflow: hidden;
}

.details-button.button-shine {
  background-color: var(--color-secondary);
  color: var(--background-text-primary);
  overflow: hidden;
  font-weight: 800;
}

.details-button .shine {
  width: 100% !important;
}

.details-button.outlined {
  bottom: 90px;
  background-color: transparent;
  color: var(--color-primary);
  box-shadow: inset 0px 0px 0px 3px var(--color-primary);
  background-color: var(--background-color-primary);
}

.details-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.details-button:disabled .shine {
  display: none;
}
