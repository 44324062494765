.slider-card,
.slider-card * {
  user-select: none;
}

.slider-card {
  width: calc(calc(100vw / 1.6) - 30px);
  height: 237px;
  border-radius: 18px;
  overflow: hidden;
  background-color: var(--background-color-secondary);
  padding: 15px;
}

.slider-card-inner {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.slider-card-image-outer {
  border-radius: 18px;
  overflow: hidden;
  background-color: var(--background-color-tertiary);
}

.slider-card-image {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px auto, cover;
  position: relative;
  height: 160px;
}

@media (min-width: 620px) {
  .slider-card {
    height: 297px;
  }

  .slider-card-image {
    height: 220px;
  }

  .slider-card-image {
    background-size: 140px auto, cover !important;
  }
}

@media (min-width: 780px) {
  .slider-card {
    width: calc(calc(100vw / 1.6) - 30px);
    height: 357px;
    max-width: 480px;
  }

  .slider-card-image {
    height: 280px;
    background-size: 160px auto, cover !important;
  }
}

.slider-card-title {
  line-height: 20px;
  font-weight: 700;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: var(--text-color-secondary);
}

.slider-card-footer {
  display: flex;
  justify-content: center;
}

.slider-card-details {
  flex-grow: 1;
  color: var(--text-color-tertiary);
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}

@media (max-width: 400px) {
  .slider-card-details {
    font-size: 10px;
  }
}

.slider-card-button {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: #FFF;
  height: 32px;
  line-height: 30px;
  width: 80px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 10px;
  cursor: pointer;
}
