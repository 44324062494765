.header,
.header * {
  user-select: none;
}

.header {
  height: 50px;
  display: flex;
  padding: 20px 20px 0px 20px;
}

.header-logo {
  flex-grow: 1;
  background-image: var(--logo);
  background-repeat: no-repeat;
  background-size: auto 34px;
  background-position: 3px 7px;
}

[data-theme="light"] .header-logo {
  background-image: url(../../assets/logo-light.png);
}

[data-theme="dark"] .header-logo {
  background-image: url(../../assets/logo-dark.png);
}
