.game {
  position: fixed;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  background-color: var(--background-color-primary);
  left: 100vw;
  top: 0px;
  z-index: 1500;
}

[data-animations="true"] .game.animate {
  transition-property: left, opacity;
  transition-duration: 0.3s;
}

.game.active {
  left: 0px;
}

.game-loading {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: calc(48vh - 100px) auto 0px auto;
}

.game-loading-logo {
  width: 100%;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.game-loading span {
  color: #1118;
  width: 30px !important;
  height: 30px !important;
}

[data-theme="dark"] .game-loading span {
  color: #FFF8;
}

.game iframe {
  height: 100%;
  width: 100%;
  border: 0;
  overflow: hidden;
}
