.email-popup {
  width: 100%;
  margin: 10px !important;
  background-color: #fff;
  max-width: 380px !important;
  border-radius: 20px !important;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.email-popup-header {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
}

.email-popup-back-button {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  color: #FFF;
  background-color: #0003;
  border-radius: 50px;
  margin-right: 15px;
  margin-left: -20px;
}

.email-popup-image {
  background-image: url(../../assets/images/mail.png);
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 90%;
}

.email-popup-description {
  text-align: center;
  padding: 0px 30px;
  font-size: 14px;
  font-weight: 600;
}

.email-popup-input {
  height: 40px;
  width: 80%;
  font-size: 14px;
  text-align: center;
  border: 0px;
  outline: none;
  border-radius: 10px;
  background-color: #1112;
  font-weight: 600;
}

.email-popup-input:focus::placeholder {
  color: transparent;
}

.email-popup-input::placeholder {
  font-weight: 400;
  font-weight: 600;
}

.email-popup-button {
  height: 40px;
  width: 120px;
  background-color: #0f55cf;
  color: #FFF;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 600;
}

.email-popup-button:not(:disabled):active {
  background-color: #1045a0;
}

.email-popup-button:disabled {
  cursor: default;
  filter: grayscale(100%);
  opacity: 0.35;
}

.email-popup-error {
  color: rgb(146, 20, 20);
}

.email-popup-link {
  cursor: pointer;
  text-decoration: underline;
  color: #06c;
}

.email-popup-link[data-busy="true"] {
  cursor: default;
  color: #0005;
}
