.payment-popup,
.payment-popup * {
  user-select: none;
}

.payment-popup {
  max-width: 300px !important;
  padding: 40px 50px;
  text-align: center;
  background-color: var(--background-color-primary) !important;
}

.payment-close-button {
  float: right;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: var(--text-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.payment-popup-image {
  height: 120px;
  margin: 0px 0px 20px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.payment-popup-title {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
  color: var(--text-color-primary);
}

.payment-popup-description {
  padding: 8px 0px;
  font-weight: 600;
  color: var(--text-color-secondary);
}

.payment-popup-amount {
  line-height: 50px;
  font-weight: 600;
  font-size: 20px;
  color: rgb(175, 15, 15);
}

[data-theme="dark"] .payment-popup-amount {
  color: rgb(200, 18, 18);
}

.payment-popup-button {
  background-color: gold;
  border: none;
  border-radius: 5px;
  height: 40px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
  color: rgb(66, 58, 18);
  cursor: pointer;
}

.payment-popup-button:disabled {
  filter: grayscale(100%);
}
