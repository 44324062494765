
.flag {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 70px;
  height: 70px;
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
}

.flag.paid-rewards {
  background-image: url(../assets/ribbons/paid-rewards.png);
}

.flag.free-rewards {
  background-image: url(../assets/ribbons/free-rewards.png);
}

.flag.global {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  background-image: url(../assets/icons/global.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
