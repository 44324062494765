.favorites-page,
.favorites-page * {
  user-select: none;
}

.favorites-page.loading {
  color: var(--color-primary) !important;
  display: flex;
  justify-content: center;
  padding-top: calc(50vh - 120px - 70px);
  opacity: 0.6;
}

.favorites-page-title {
  font-weight: 700;
  font-size: 25px;
  padding: 10px 20px 0px 20px;
  color: var(--text-color-primary);
}

.favorites-page.empty {
  padding: calc(50vh - 20px - 70px - 160px) 30px 0px 30px;
  display: flex;
  justify-content: center;
  color: var(--text-color-tertiary);
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}

.favorites-page-content.listing {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px;
}

.favorites-page-footer {
  padding: 50px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorites-page-footer-button {
  border: none;
  outline: none;
  background-color: var(--color-primary);
  color: #FFF;
  height: 38px;
  line-height: 30px;
  width: 120px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 10px;
  cursor: pointer;
}
