.shine {
  width: inherit;
  height: inherit;
  position: relative;
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.85;
  pointer-events: none;
  z-index: 0;
}

[data-animations="false"] .shine {
  display: none;
}

.shine::before {
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 10;
  animation-name: shine-animation;
  animation-duration: 1.75s;
  animation-iteration-count: 1;
  pointer-events: none;
}

.shine.gold::before {
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 226, 89, 0.6) 60%,
      rgba(255, 167, 81, 0.6) 80%,
      rgba(255, 167, 81, 0) 100%);
}

.shine.white::before {
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 60%,
      rgba(255, 255, 255, 0.6) 80%,
      rgba(255, 255, 255, 0) 100%);
}

@-webkit-keyframes shine-animation {
  100% {
    left: 125%;
  }
}

@keyframes shine-animation {
  100% {
    left: 125%;
  }
}
